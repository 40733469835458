/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetJobDetailsQuery } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { isSalesforceApplication } from "../../helpers/url-params";
import { getLocale } from "../../utility/locale-helper";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { resolveSdaResponse } from "../../helpers/configHelper";
import { JobDetailsModel } from "../../utility/application-data";

export const useGetJobDetails = () => {
  const { jobDetails } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const [getJobDetails] = useLazyGetJobDetailsQuery();

  useEffect(() => {
    if (jobDetails) return;

    if (applicationId) {
      getJobDetails(
        { applicationId, isSalesforceApplication: isSalesforceApplication(applicationId), locale: getLocale() },
        true
      ).then(({ error, data }) => {
        const jobData = error && "data" in error ? (error?.data as JobDetailsModel) : data;
        if (!jobData) {
          throw new Error("Retrieved invalid JobDetails from the API");
        }

        if (jobData?.error) {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.APP_ERROR,
              value: jobData?.error,
            })
          );
        }

        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.JOB_DETAILS,
            value: jobData,
          })
        );
        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.SDA_DATA,
            value: resolveSdaResponse(jobData),
          })
        );
      });
    }
  }, [applicationId, dispatch, getJobDetails, jobDetails]);

  return jobDetails;
};
